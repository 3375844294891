import React, { ReactNode } from "react"

import classNames from "classnames"
import { colorOptions } from "../../../types/options"
import { twMerge } from "tailwind-merge"

export enum BackgroundColor {
    "White" = "#fff",
    "Green" = "#919a76",
    "Grey" = "#f2f0ee",
    "DarkBlumine" = "#01273E",
}

export interface BackgroundProps {
    children: ReactNode
    color?: BackgroundColor
    className?: string
}

const Background = ({ children, color = BackgroundColor.White, className }: BackgroundProps) => {
    return (
        <div
            className={twMerge(
                classNames(className, {
                    "bg-gradient-to-r from-green-dark via-green-light to-green-dark": color === BackgroundColor.Green,
                    "bg-background-tide": color === BackgroundColor.Grey, 
                    "bg-darkblumine": color === BackgroundColor.DarkBlumine,
                })
            )}
        >
            {children}
        </div>
    )
}

export const backgroundOptions = colorOptions<typeof BackgroundColor>(BackgroundColor)
export const backgroundGreyScaleColorsOptions = backgroundOptions.filter(
    option => option.value.color !== BackgroundColor.Green && option.value.color !== BackgroundColor.DarkBlumine
)

export default Background
