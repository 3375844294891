import { Icons, options } from "../../../types"

import Icon from "../icon/icon"
import { Link } from "react-bricks/frontend"
import React from "react"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"

export enum ButtonStyle {
    Primary = "primary",
    Secondary = "secondary",
    Ghost = "ghost",
    Disabled = "disabled",
}

export enum ButtonSize {
    Small = "sm",
    Base = "base",
}

interface CommonButtonProps {
    title: string
    icon?: Icons
    style?: ButtonStyle
    size?: ButtonSize
    className?: string
    disabled?: boolean
    fullWidth?: boolean
}

type ConditionalButtonProps =
    | {
          href: string
          target?: string
          onClick?: never
      }
    | {
          href?: never
          target?: never
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
      }

type ButtonProps = CommonButtonProps & ConditionalButtonProps

const Button = ({
    href,
    target,
    title,
    icon,
    style = ButtonStyle.Primary,
    size = ButtonSize.Base,
    onClick,
    className,
    disabled,
    fullWidth,
}: ButtonProps) => {
    const classes = twMerge(
        classNames("w-full sm:w-auto h-14 items-center justify-center rounded-sm px-5 md:px-10 text-base", {
            "bg-blue text-white": style === ButtonStyle.Primary,
            "border border-blue border-solid text-blue": style === ButtonStyle.Secondary,
            "border border-white border-solid text-white": style === ButtonStyle.Ghost,
            "bg-light-grey text-white cursor-not-allowed pointer-events-none": style === ButtonStyle.Disabled,
            "xl:h-19 xl:rounded-base xl:text-lg": size === ButtonSize.Base,
            "flex": fullWidth,
            "inline-flex": !fullWidth,
        }),
        className
    )

    const innerContent = (
        <>
            {icon && <Icon icon={icon} className="mr-2.5 lg:mr-3" />}
            <span>{title}</span>
        </>
    )

    return onClick ? (
        <button type="button" className={classes} onClick={onClick} disabled={disabled}>
            {innerContent}
        </button>
    ) : disabled ? (
        <div className={classes}>{innerContent}</div>
    ) : (
        <Link href={href} target={target} className={classes}>
            {innerContent}
        </Link>
    )
}

export const buttonStyleOptions = options<typeof ButtonStyle>(ButtonStyle)

export default Button
