import { closeOverlay, openOverlay, useOverlayDispatch } from "../../shared/context/overlay"
import { types, useAdminContext } from "react-bricks"

import { Icon } from "../components/icon"
import { Icons } from "../../types"
import React from "react"
import ReactMarkdown from "react-markdown"
import { TextLink } from "../components/text"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

const WhatsappButton: types.Brick = () => {
    const { t } = useTranslation("translations")
    const overlayDispatch = useOverlayDispatch()

    const { isAdmin } = useAdminContext()

    return (
        <button
            className={classNames(
                "bottom-0 right-0 z-5 m-2.5 flex h-15 w-15 items-center justify-center overflow-hidden rounded-full bg-gradient-to-b from-[#9ee48e] to-[#67b74c] sm:hidden",
                {
                    fixed: !isAdmin,
                    "ml-auto": isAdmin,
                }
            )}
            type="button"
            onClick={() =>
                openOverlay(
                    overlayDispatch,
                    <div>
                        <ReactMarkdown
                            components={{
                                h4: ({ children }) => <h4 className="mb-4 text-xl font-semibold max-w-[calc(100%_-_30px)]">{children}</h4>,
                                p: ({ children }) => <p className="mb-4 text-md last:mb-0 whitespace-pre-wrap">{children}</p>,
                                strong: ({ children }) => <strong className="font-semibold">{children}</strong>,
                                a: ({ children, href }) => <a href={href} className="text-blue">{children}</a>,
                            }}
                        >
                            {t("whatsapp_button_text")}
                        </ReactMarkdown>
                        <TextLink
                            to={`/${t("whatsapp_button_path")}`}
                            icon={Icons.link}
                            title={t("whatsapp_button_link")}
                            withOnClick={() => closeOverlay(overlayDispatch)}
                        />
                    </div>
                )
            }
        >
            <Icon icon={Icons.whatsapp} className="text-3xl leading-none text-white" />
        </button>
    )
}

export default WhatsappButton

WhatsappButton.schema = {
    name: "WhatsappButton",
    label: "Whatsapp Button",
}
