import { Background, BackgroundColor, Container, Spacer, SpacerVariant } from "../components/layout"
import React, { useRef } from "react"
import { Repeater, Text, types } from "react-bricks/frontend"

import { ConditionalWrapper } from "../components/util"
import { Icons } from "../../types"
import { PDPImageProps } from "./pdp/Image"
import { TypographyElement } from "../Typography"
import classNames from "classnames"
import { useCheckForEmptyText } from "../../hooks/use-check-for-empty-text"
import { useSlider } from "../../hooks/use-slider"
import { useWindowSize } from "../../hooks/use-window-size-admin"

export interface PDPProps {
    spacerTop: boolean
    spacerBottom: boolean
    id?: string
    image?: {
        id: string
        props: PDPImageProps
        type: "PDPImage"
    }[]
    badge?: TypographyElement[]
}

const PDP: types.Brick<PDPProps> = ({ spacerTop, spacerBottom, id, image, badge }) => {
    const sliderRef = useRef<null | HTMLDivElement>(null)

    const length = image ? image.length : 0

    const { handleDragStart, handleDragMove, handleDragEnd, index, lastIndex, transition, goTo } = useSlider(
        length,
        sliderRef,
        0
    )

    const emptyBadge = useCheckForEmptyText(badge)

    const { width } = useWindowSize({ initializeWithValue: false })

    return (
        <Background color={BackgroundColor.White} className="overflow-hidden">
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : SpacerVariant.Small} />
                <div
                    className="grid grid-cols-1 sm:grid-cols-[320px_auto] sm:gap-x-10 md:grid-cols-[462px_auto] lg:grid-cols-[530px_auto] lg:gap-x-16 xl:grid-cols-[708px_auto] xl:gap-x-20"
                    id={id}
                >
                    <div>
                        <div className="-mx-3 sm:mx-0 sm:overflow-hidden">
                            <ConditionalWrapper
                                wrapper={children => (
                                    <div
                                        className={classNames("grid gap-x-1.5 sm:gap-x-6", {
                                            "transition-transform duration-[250ms] ease-in-out": transition,
                                            "cursor-grab active:cursor-grabbing": length > 1,
                                        })}
                                        style={
                                            {
                                                width: `calc(var(--child-width) * ${length} + var(--gap) * ${
                                                    length - 1
                                                })`,
                                                transform: `translateX(calc(${
                                                    -1 * index
                                                } * (100% + var(--gap)) / ${length}))`,
                                                gridTemplateColumns: `repeat(${length}, 1fr)`,
                                                "--child-width": "100%",
                                                // @ts-ignore
                                                "--gap": `${width < 768 ? 0.375 : 1.5}rem`,
                                            } as React.CSSProperties
                                        }
                                        onTouchStart={handleDragStart}
                                        onMouseDown={handleDragStart}
                                        onTouchMove={handleDragMove}
                                        onMouseMove={handleDragMove}
                                        onTouchEnd={handleDragEnd}
                                        onMouseUp={handleDragEnd}
                                        onMouseLeave={handleDragEnd}
                                        role="none"
                                        ref={sliderRef}
                                    >
                                        {children}
                                    </div>
                                )}
                                condition={length > 1}
                            >
                                <Repeater propName="image" />
                            </ConditionalWrapper>
                        </div>
                        {length > 1 && (
                            <div className="mt-3">
                                <div className="flex justify-center">
                                    {[...Array(length)].map((_, i) => (
                                        // eslint-disable-next-line
                                        <button
                                            type="button"
                                            className={classNames(
                                                "mr-1.5 h-[9px] w-[9px] rounded-full bg-disabled last:mr-0",
                                                {
                                                    "bg-light-grey": lastIndex === i,
                                                }
                                            )}
                                            onClick={() => goTo(i)}
                                            key={i}
                                        ></button>
                                    ))}
                                </div>
                                <div className="mt-5 hidden justify-center sm:flex lg:mt-7">
                                    <button
                                        className={classNames("mr-3.5 hidden select-none text-blue sm:block lg:mr-5", {
                                            "pointer-events-none text-disabled": lastIndex < 1,
                                        })}
                                        type="button"
                                        onClick={() => goTo(lastIndex - 1)}
                                    >
                                        <i className="lavita-icon text-[42px] lg:text-[52px]">{Icons.back}</i>
                                    </button>
                                    <button
                                        className={classNames("hidden select-none text-blue sm:block", {
                                            "pointer-events-none text-disabled": lastIndex + 1 >= length,
                                        })}
                                        type="button"
                                        onClick={() => goTo(lastIndex + 1)}
                                    >
                                        <i className="lavita-icon scale-x-[-1] text-[42px] lg:text-[52px]">
                                            {Icons.back}
                                        </i>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mt-7 flex flex-col sm:mt-5">
                        <div className="flex justify-between border-b border-background-tide pb-5 sm:pb-7 lg:pb-10">
                            <div className="flex flex-col flex-grow">
                                <Text
                                    propName="subHeadline"
                                    renderBlock={({ children }) => (
                                        <h2 className="mb-2 sm:mb-3 text-xs lg:text-sm">{children}</h2>
                                    )}
                                    placeholder="Lorem ipsum"
                                />
                                <Text
                                    propName="headline"
                                    renderBlock={({ children }) => (
                                        <h1 className="font-bold text-xl md:text-3xl lg:text-5xl">
                                            {children}
                                        </h1>
                                    )}
                                    placeholder="Lorem ipsum"
                                />
                                <Text
                                    propName="description"
                                    renderBlock={({ children }) => (
                                        <p className="mt-2 sm:mt-3 text-lg md:text-xl lg:mt-3.5 lg:text-2xl">
                                            {children}
                                        </p>
                                    )}
                                    placeholder="Lorem ipsum dolor sit amet"
                                />
                            </div>
                            <div className="flex flex-col text-right flex-grow flex-shrink-0">
                                <Text
                                    propName="label"
                                    renderBlock={({ children }) => (
                                        <span className="mb-2 sm:mb-3 text-xs lg:text-sm">
                                            {children}
                                        </span>
                                    )}
                                    placeholder="Lorem ipsum"
                                />
                                <Text
                                    propName="price"
                                    renderBlock={({ children }) => (
                                        <strong className="font-bold text-xl md:text-3xl lg:text-5xl">
                                            {children}
                                        </strong>
                                    )}
                                    placeholder="Lorem"
                                />
                                <div>
                                    <Text
                                        propName="badge"
                                        renderBlock={({ children }) => (
                                            <span
                                                className={classNames(
                                                    "rounded-full font-bold text-xs lg:text-sm",
                                                    {
                                                        "my-3.5 bg-background-tide px-2.5 py-1.5 sm:px-3": !emptyBadge,
                                                    }
                                                )}
                                            >
                                                {children}
                                            </span>
                                        )}
                                        placeholder="Lorem ipsum dolor"
                                    />
                                </div>
                                <Text
                                    propName="smallprint"
                                    renderBlock={({ children }) => (
                                        <small className="mt-2 sm:mt-3 text-xs lg:mt-3.5 lg:text-sm">
                                            {children}
                                        </small>
                                    )}
                                    placeholder="Lorem ipsum dolor sit"
                                />
                            </div>
                        </div>
                        <Repeater
                            propName="repeater"
                            renderWrapper={children => (
                                <div className="mt-5 flex flex-col gap-5 sm:mt-7 lg:mt-10 lg:gap-7">{children}</div>
                            )}
                            itemProps={{ fullWidth: true }}
                        />
                    </div>
                </div>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : SpacerVariant.Small} />
            </Container>
        </Background>
    )
}

PDP.schema = {
    name: "PDP",
    label: "PDP",
    repeaterItems: [
        {
            name: "image",
            items: [
                {
                    type: "PDPImage",
                    label: "Image",
                    min: 1,
                },
            ],
        },
        {
            name: "repeater",
            items: [
                {
                    type: "PDPIconTextGrid",
                    label: "Icon Text Grid",
                    min: 0,
                },
                {
                    type: "Button",
                    label: "Button",
                    min: 0,
                },
                {
                    type: "GreenText",
                    label: "Green Text",
                    min: 0,
                },
                {
                    type: "List",
                    label: "List",
                    min: 0,
                },
                {
                    type: "Trust",
                    label: "Trust Badge",
                    min: 0,
                    max: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): PDPProps => ({
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default PDP
