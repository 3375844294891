import { Align, Color, MaxWidth, TypographyAlign, TypographyMaxWidth } from "../components/typography"
import {
    Background,
    BackgroundColor,
    Container,
    Spacer,
    SpacerVariant,
    backgroundGreyScaleColorsOptions,
} from "../components/layout"
import { Repeater, types } from "react-bricks/frontend"
import Typography, { TypographyElement } from "../Typography"

import React from "react"

interface FAQsProps {
    background: { color: BackgroundColor } | BackgroundColor
    text: TypographyElement[]
    spacerTop: boolean
    spacerBottom: boolean
}

const FAQs: types.Brick<FAQsProps> = ({ background, spacerTop, spacerBottom }) => {
    const bgColor = typeof background === "object" ? background.color : background

    return (
        <Background color={bgColor}>
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : undefined} />
                <Align align={TypographyAlign.Center}>
                    <MaxWidth mdMaxWidth={TypographyMaxWidth["70%"]}>
                        <Color>
                            <Typography allowList={["h3", "small", "sup", "link"]} />
                        </Color>
                    </MaxWidth>
                </Align>
                <Spacer variant={SpacerVariant.Small} />
                <div>
                    <Repeater propName="repeater" itemProps={{bgColor: bgColor}} />
                </div>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : undefined} />
            </Container>
        </Background>
    )
}

FAQs.schema = {
    name: "FAQs",
    label: "FAQs",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "FAQ",
                    label: "FAQ",
                    min: 1,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "background",
            label: "Background",
            type: types.SideEditPropType.Select,
            selectOptions: {
                options: backgroundGreyScaleColorsOptions,
                display: types.OptionsDisplay.Color,
            },
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): FAQsProps => ({
        background: { color: BackgroundColor.White },
        text: [
            {
                type: "h3",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet",
                    },
                ],
            },
            {
                type: "paragraph",
                children: [
                    {
                        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc placerat sagittis faucibus.",
                    },
                ],
            },
        ],
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default FAQs
