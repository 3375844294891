import ImageEditor, { AspectRatio } from "../../Image"

import React from "react"
import { types } from "react-bricks/frontend"

export interface PDPImageProps {
    index?: number
}

const PDPImage: types.Brick<PDPImageProps> = ({ index }) => {
    return (
        <div key={index} className="overflow-hidden rounded-lg">
            <div className="aspect-h-1 aspect-w-1">
                <ImageEditor propName="image" alt="Product" maxWidth={708} aspectRatio={AspectRatio["1/1"]} />
            </div>
        </div>
    )
}

PDPImage.schema = {
    name: "PDPImage",
    label: "Image",
    hideFromAddMenu: true,
}

export default PDPImage
